import React, { useEffect, useState } from 'react';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import Home from '../Home';

// function TabPanel(props) {
//   const {
//     children, value, index, ...other
//   } = props;
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography component="span">{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

export default function MyComponent() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  function handleLinksData(data) {
    if (data) {
      setIsLoaded(true);
      setItems(data);
    } else {
      setError({
        message:
          'אין קישורים או בעייה בעליית התוכן, נא לנסות שנית או לפנות לתמיכה',
      });
    }
  }

  useEffect(() => {
    fetch('https://api.tguva.app/api/getLinks.php')
      .then((res) => res.json())
      .then(
        (result) => {
          handleLinksData(result);
        },
        (e) => {
          setIsLoaded(true);
          setError(e);
        },
      );
  }, []);

  if (error) {
    return <div>{error.message}</div>;
  } if (!isLoaded) {
    return <div className="d-flex align-items-center justify-content-center"><img src="https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/images/loading.gif" alt="" /></div>;
  }
  return <Home data={items} />;
}

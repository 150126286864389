import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function QnA() {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [qs, setQs] = React.useState([]);

  useEffect(() => {
    fetch('https://api.tguva.app/api/qna.json')
      .then((res) => res.json())
      .then(
        (result) => {
          setQs(result);
          setIsLoaded(true);
        },
        () => {
          setIsLoaded(true);
          setError('הייתה בעיה בטעינת השאלות ותשובות, צאו להגיב בכל זאת! :)');
        },
      );
  }, []);

  function answers(title, data, key) {
    return (
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography component="span" className={classes.heading}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="span">
            {data.map((item, i) => (
              <div className="lang-answer" style={{ marginBottom: '25px' }}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    style={{
                      border: 'solid',
                      borderRadius: '75px',
                      padding: i > 10 ? '5px 8px' : '5px 12px',
                    }}
                  >
                    {i + 1}
                  </div>
                  <div
                    style={{ paddingRight: '10px' }}
                    dangerouslySetInnerHTML={{
                      __html: item[`q-answer-${key}`],
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(item[`q-answer-${key}`]);
                  }}
                >
                  העתק
                </Button>
              </div>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }

  if (error) {
    return <div>{error.message}</div>;
  } if (!isLoaded) {
    return <div>טוען שאלות ותשובות...</div>;
  }
  return (
    <>
      <div>
        <input placeholder="סנן " />
      </div>

      <div className={classes.root}>
        {Object.keys(qs).map((item) => {
          const q = qs[item];
          return (
            <React.Fragment key={item}>
              <div className="questionsCont">
                {q['heb-question'] && (
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      component="span"
                      className={classes.heading}
                    >
                      {q['heb-question'] && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: q['heb-question'],
                        }}
                      />
                      )}
                      {q['eng-question'] && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: q['eng-question'],
                        }}
                      />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="span">
                      {q['heb-answers']
                            && q['heb-answers'].length
                            && answers('עברית', q['heb-answers'], 'heb')}
                      {q['eng-answers']
                            && q['eng-answers'].length
                            && answers('אנגלית', q['eng-answers'], 'eng')}
                      {q['arab-answers']
                            && q['arab-answers'].length
                            && answers('arabic', q['arab-answers'], 'arab')}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

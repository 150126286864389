import React from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Links from './components/links';
import DialogProvider from './DialogProvider';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function App() {

  const { t } = useTranslation();
  return (
    <Container>
      <CssBaseline />
      <DialogProvider>
        <div className="App">
          <div style={{ textAlign: 'center' }}>
            <img
              src="https://cdn.pixabay.com/photo/2015/03/14/20/53/israel-673776_1280.png"
              style={{ width: '220px' }}
              alt=""
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            {i18n.language === 'he' && 
              <p className="my-5">
                ברוכים הבאים למערכת תגובה!!
                <br />
                המשימה שלנו היא לנצח את הפייק ניוז והחד צדדיות בתגובות ברחבי הרשת
                <br />
                כל תגובה שתגיבו בקישורים שמוצגים כאן יעזרו עוד ועוד במשימה הזאת!
              </p>
            }
            
            {i18n.language === 'en' && 
            <p className="my-5"> 
              Welcome to tguva.app, Where you can help spread the truth about
              Israel.
              {' '}
              <br />
              Our mission in tguva is to find fake news on Israel and explain
              the truth!
              {' '}
              <br />
              Every comment you make on any of the links provided will give a
              fair fight in the cause of fighting fake news on Israel
            </p>
}
          </div>

          <Links />
        </div>
      </DialogProvider>
    </Container>
  );
}

export default App;

import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const WORDPRESS_API_URL = 'https://api.tguva.app';

function PostForm() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    post_title: '',
    post_content: '',
    article_link: '',
    htur: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        WORDPRESS_API_URL + '/wp-json/wp/v2/posts', // Replace with the correct API endpoint
        {
          title: formData.post_title,
          type: 'il_links',
          fields: {
            article_link: formData.article_link,
            article_htur: formData.article_htur,
          }
        },
      );

      console.log('Post created successfully:', response.data);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error creating post:', error);
      // Handle error (e.g., display an error message)
    }
  };

  const getUsers = async () => {
    const response = await axios.get(`${WORDPRESS_API_URL}/wp-json/wp/v2/users`, {
      headers: {'Access-Control-Allow-Origin': '*'}
    });
  
    return response.data;
  };
  
  const getTokens = async () => {
    const users = await getUsers();
  
    const tokens = users.map((user) => user.token);
  
    return tokens;
  };
  

  console.log('token:', getTokens());



  return (
    <div>
      <form onSubmit={handleSubmit} className="d-flex">
        <label htmlFor="post-title">
          {t('postform_title')}
          <input
            type="text"
            id="post-title"
            name="post_title"
            value={formData.post_title}
            onChange={handleChange}
            required
          />
        </label>

        <label htmlFor="post-content">
        {t('postform_article_link')}:
          <textarea
            id="post-content"
            name="article_link"
            value={formData.article_link}
            onChange={handleChange}
            required
          />
        </label>

        <label htmlFor="custom-field">
          {t('postform_htur')}:
          <input
            type="text"
            id="htur"
            name="htur"
            value={formData.htur}
            onChange={handleChange}
          />
        </label>

        <input type="submit" value={t('submit')} />
      </form>
    </div>
  );
}

export default PostForm;

import React, { useEffect } from 'react';

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useDialog } from '../../../DialogProvider';
import QnA from '../../QnA';

export default function SimpleTabs() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [jsonData, setJsonData] = React.useState();
  const [openDialog, closeDialog] = useDialog();

  useEffect(() => {
    fetch('https://api.tguva.app/api/fakeNews.php')
      .then((res) => res.json())
      .then(
        (result) => {
          setJsonData(JSON.parse(result));
          setIsLoaded(true);
        },
        () => {
          setIsLoaded(true);
          // setError('הייתה בעיה בטעינת השאלות ותשובות, צאו להגיב בכל זאת! :)');
        },
      );
  }, []);

  const qnaPopup = () => {
    openDialog({
      children: (
        <>
          <DialogTitle>שאלות ותשובות</DialogTitle>
          <DialogContent>
            <QnA />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeDialog()}>
              סגירה
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  const onOpenDialog = (data) => {
    openDialog({
      children: (
        <>
          <DialogTitle>לפני היציאה להגיב</DialogTitle>
          <DialogContent>
            נא להיזכר שאתם מייצגים את ישראל!
            <br />
            נא לא לקלל, להסית וכו
            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                onClick={() => window.open(data.article_link, '_blank')}
              >
                למעבר לקישור
              </Button>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button color="primary" onClick={() => qnaPopup()}>
                מאגר שאלות ותשובות
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeDialog()}>
              סגירה
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  if (!isLoaded) {
    return <div>טוען...</div>;
  }
  return (
    <div className="urlsCont">
      {jsonData.map((item) => {
        const num = Math.ceil(Math.random() * 1000);
        return (
          <div className="tguva-item pointer">
            <div onClick={() => onOpenDialog(item)} key={item.post_title}>
              {item.article_link}
            </div>
            <div className="numClicks">
              <span>מס קליקים:</span>
              {' '}
              {num}
            </div>
          </div>
        );
      })}
    </div>
  );
}

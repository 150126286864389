import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useDialog } from '../../../DialogProvider';
import QnA from '../../QnA';

export default function SimpleTabs(props) {
  const [openDialog, closeDialog] = useDialog();

  const qnaPopup = () => {
    openDialog({
      children: (
        <>
          <DialogTitle>שאלות ותשובות</DialogTitle>
          <DialogContent>
            <QnA />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeDialog()}>
              סגירה
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  const onOpenDialog = (data) => {
    openDialog({
      children: (
        <>
          <DialogTitle>לפני היציאה להגיב</DialogTitle>
          <DialogContent>
            נא להיזכר שאתם מייצגים את ישראל!
            <br />
            נא לא לקלל, להסית וכו...
            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                onClick={() => window.open(data.article_link, '_blank')}
              >
                למעבר לקישור
              </Button>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button color="primary" onClick={() => qnaPopup()}>
                מאגר שאלות ותשובות
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeDialog()}>
              סגירה
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  const { data } = props;

  return (
    <div className="urlsCont">
      {data
        && data.urls
        && Object.keys(data.urls).map((item) => Object.keys(data.urls[item]).map((linkObj) => {
          const obj = data.urls[item][linkObj];
          const num = Math.ceil(Math.random() * 1000);
          return (
            <div className="tguva-item pointer">
              <div onClick={() => onOpenDialog(obj)} key={item}>
                {obj.article_link}
              </div>
              <div className="numClicks">
                <span>קליקים:</span>
                {' '}
                {num}
              </div>
            </div>
          );
        }))}
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import App from './App';
import PostForm from './components/PostForm/PostForm';
import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';
import './i18n/i18n';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/new-post',
    element: <PostForm />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <LanguageSwitcher />
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root'),
);

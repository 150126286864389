import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function LanguageSwitcher() {
  // Initialize the current language from localStorage, defaulting to English ('en')
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('language') || 'en',
  );

  useEffect(() => {
    // Update localStorage with the selected language whenever it changes
    localStorage.setItem('language', currentLanguage);
    if (currentLanguage === 'en') {
      document.body.classList.add('eng');
    } else {
      document.body.classList.remove('eng');
    }
  }, [currentLanguage]);

  // const { i18n } = useTranslation();
  const toggleLanguage = () => {
    setCurrentLanguage((prevLanguage) => (prevLanguage === 'en' ? 'he' : 'en'));
    console.log('i18n.language', i18n.language);
    i18n.changeLanguage(i18n.language === 'en' ? 'he' : 'en');
  };

  return (
    <div className="language-switcher">
      <span onClick={toggleLanguage} className="pointer">
        Switch language {currentLanguage === 'en' ? (
          <img
            src="https://flagcdn.com/16x12/il.png"
            srcSet="https://flagcdn.com/32x24/il.png 2x,
            https://flagcdn.com/48x36/il.png 3x"
            width="16"
            height="12"
            alt="Switch to Hebrew" />
        ) : (
          <img
            src="https://flagcdn.com/16x12/us.png"
            srcSet="https://flagcdn.com/32x24/us.png 2x,
    https://flagcdn.com/48x36/us.png 3x"
            width="16"
            height="12"
            alt="Switch to english" />

        )}
      </span>
    </div>
  );
}

export default LanguageSwitcher;

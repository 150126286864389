import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  Tooltip,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useDialog } from '../../../DialogProvider';
import QnA from '../../QnA';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openDialog, closeDialog] = useDialog();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const qnaPopup = () => {
    openDialog({
      children: (
        <>
          <DialogTitle>שאלות ותשובות</DialogTitle>
          <DialogContent>
            <QnA />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeDialog()}>
              סגירה
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  const onOpenDialog = (data) => {
    openDialog({
      children: (
        <>
          <DialogTitle>לפני היציאה להגיב</DialogTitle>
          <DialogContent>
            נא להיזכר שאתם מייצגים את ישראל!
            <br />
            נא לא לקלל, להסית וכו...
            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                onClick={() => window.open(data.article_link, '_blank')}
              >
                למעבר לקישור
              </Button>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button color="primary" onClick={() => qnaPopup()}>
                מאגר שאלות ותשובות
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeDialog()}>
              סגירה
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  const { data } = props;

  return (
    <div className={classes.root}>
      <AppBar position="static" className="bySiteAppBar">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {data
            && data.websites
            && data.websites
              .map((item, i) => <Tab label={item} {...a11yProps(i)} key={item.name} />)}
        </Tabs>
      </AppBar>

      {data
        && data.urls
        && Object.keys(data.urls).map((item, i) => (
          <TabPanel value={value} index={i} key={item.name}>
            {data
              && data.urls
              && Object.keys(data.urls[item]).map((linkObj) => {
                const obj = data.urls[item][linkObj];
                const num = Math.ceil(Math.random() * 1000);
                return (
                  <Tooltip title={obj.link_desc} key={item}>
                    <div className="tguva-item pointer">
                      <div
                        onClick={() => onOpenDialog(obj)}
                        key={item}
                      >
                        {obj.article_link}
                      </div>
                      <div className="numClicks">
                        <span>מס קליקים:</span>
                        {' '}
                        {num}
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
          </TabPanel>
        ))}
    </div>
  );
}
